import { getHisenseStock,exportStock, getConditionOptions } from "./api.js";
import navList from "../navList";
import Util from "@/utils/utils";

export default {
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  data() {
    return {
      pageLoadFlag: false,
      navList: [],
      allowSleAll:true,
      expLoading:false,
      showTotalColumns: ["实际库存"],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/stockCenter/distributorStock",
          name: "distributorStock",
          title: "分销商库存"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10,
        sortName: "",
        sortOrder: ""
      },
      count: {
        totalOccQty: 0,
        totalActQty: 0,
        totalAvaQty: 0
      },
      scroll: {
        // x: 'true',
        // y: 400
      },
      columns: [
        {
          label: "分销商编码-名称",
          prop: "dealerCodeName",
          width: 300,
          ellipsis: true,
         sortable: "custom",
          sortName: "dealerCode",
          key: "dealerCodeName"
        },
        {
          label: "产品型号",
          prop: "model",
          ellipsis: true,
         sortable: "custom",
          sortName: "model",
          key: "model",
          width: 260
        },
        {
          label: "颜色",
          prop: "colour",
          ellipsis: true,
          key: "colour",
          width: 100
        },
        {
          label: "物料组",
          prop: "materialGroupName",
          ellipsis: true,
          key: "materialGroupName",
          width: 100
        },
        {
          label: "仓库",
          prop: "warehouseName",
          ellipsis: true,
          key: "warehouseName",
          width: 200
        },
        // {
        //   label: "库存状态",
        //   prop: "invStatusName",
        //   ellipsis: true,
        //   key: "invStatusName",
        //   width: 100
        // },
        {
          label: "质量等级",
          prop: "invStatusName",
          ellipsis: true,
          width: 120,
          key: "invStatusName"
        },
        {
          label: "补差类型",
          prop: "invStatusTypeName",
          ellipsis: true,
          key: "invStatusTypeName",
          width:120
        },
        // {
        //   label: "实际库存",
        //   prop: "totalBig",
        //   key: "totalBig",
        //   align: "right",
        //   width: 90
        // },
        {
          label: "实际库存",
          prop: "totalBig",
          ellipsis: true,
          key: "totalBig",
          width: 120
        },

        {
          label: "预留库存",
          prop: "bigQtyLock",
          key: "bigQtyLock",
          align: "right",
          width: 120
        },
        {
          label: "可用库存",
          prop: "bigQty",
          key: "bigQty",
          align: "right",
          width: 120
        },
      ],
      data: [],
      dataAll: [],
      terms: {},
      showLoading:false,
      exportVisible: false, //导出弹窗
      checkFxId: undefined, // 导出弹窗中选择的分销商
      fxIdList: [], // 导出弹窗中分销商列表
    };
  },
  computed: {
    // 实际统计
    totalBig: function() {
      return this.data.reduce((sum, item) => {
        return sum + (parseInt(item.bigQty) || 0);
      }, 0);
    }
  },
  created() {
    this.navList = navList.nav1;
    // this.getData();
    let params = {
      cisCode: this.$store.state.user.userInfo.customer.cisCode,
      // userAccount: this.$store.state.user.userInfo.account.account,
      field: "customerCode",
      // userAccount: this.$store.state.user.userInfo.customer.customerCode,
      formCode: "dmsInventoryHisenseCondition"
    }
    // 获取导出弹窗分销商
    getConditionOptions(params).then(res=>{
      const resData = JSON.parse(res.data);
      this.fxIdList = resData.data;
    })
  },
  methods: {
    // 导出 分销商 全选
    selectAll() {
      this.checkFxId = this.fxIdList.map(j=>j.code);
      this.$forceUpdate()
    },
    // // 导出 分销商 清空 -- 清空select绑定的值
    clearAll() {
      this.checkFxId = undefined;
      this.$forceUpdate()
    },
    searchFun(arg) {
      this.terms = arg[1];
      this.pager.pageNo = 1
      this.getData();
    },
    //排序change事件
    onTableChange( sorter) {
      const sortName = this.columns.filter(it => it.prop == sorter.prop);
      if (sortName.length < 1) {
        return;
      }
      if (sorter && sorter.order) {
        this.pager.sortOrder =
            sorter.order == "ascending"
                ? "asc"
                : sorter.order == "descending"
                ? "desc"
                : "";
        this.pager.sortName = sortName[0].sortName;
      } else {
        this.pager.sortOrder = "";
        this.pager.sortName = "";
      }
      this.getData();
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
  },
    exportFile(){
      if(!this.checkFxId){
        return this.$message.info('请选择至少选择一个分销商')
      }
      this.expLoading = true;
      const pageFxObj = {
        autoQuery: "1",
        extSql: null,
        field: "customerCode",
        fieldType: "string",
        inputType: "select",
        logic: "=",
        multiple: "1",
        sqlField: "dealerCode",
        value:  this.checkFxId.toString()
      }
      const paramList = [...this.terms.paramList, ...[pageFxObj]];
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        // terms: { model: "", colour: "", dealerName: "" },
        terms: { model: "", colour: "", dealerName: "", paramList, },
        // terms: { model: "", colour: "", dealerName: "", ...this.terms },
        page: {
          pageNo: 1,
          pageSize: 300000,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        },
        loginType: "CS"
      };
      exportStock(data).then(res => {
        Util.blobToJson(res.data).then(content=>{
          if(content&&content.msg=='success'){
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err=>{
          Util.downloadFile(res.data,'分销商库存.xls')
        }).finally(()=>{
          this.exportVisible = false;
          this.expLoading = false;
          this.checkFxId = undefined;
        })
      }).catch(err=>{
        this.expLoading = false;
        this.exportVisible = false;
        this.checkFxId = undefined;
      });
    },
    getData() {
      this.data = [];
      this.pager.count = 0;
      this.count.totalAvaQty = 0;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        // terms: { model: "", colour: "", dealerName: "" },
        terms: { model: "", colour: "", dealerName: "", ...this.terms },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        },
        loginType: "CS"
      };
      this.showLoading = true
      getHisenseStock(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = JSON.parse(res);
        }
        if(data.code != 0){
          this.$message.warning(data.msg)
          return
        }
        data.data.forEach(item => {
          item.dealerCodeName = item.dealerCode + "-" + item.dealerName;
        });
        const start = (this.pager.pageNo - 1) * this.pager.pageSize;

        this.dataAll = data.data || [];
        data.data = data.data || [];
        
        this.data = data.data.slice(start, start + this.pager.pageSize);

        this.pager.count = data.page.totalCount;
        // this.data.push({
        //   dealerCodeName: "合计",
        //   bigQty: this.data.reduce((sum, item) => {
        //     return sum + (parseInt(item.bigQty) || 0);
        //   }, 0)
        // });
        this.count.totalOccQty = data.page.map.totalOccQty;
        this.count.totalActQty = data.page.map.totalActQty;
        this.count.totalAvaQty = data.page.map.totalAvaQty;
      }).finally(()=>{
        this.showLoading = false
      });;
    },
    //前端分页
    pageChangeGetData(){
      const start = (this.pager.pageNo - 1) * this.pager.pageSize;
      this.data = this.dataAll.slice(start, start + this.pager.pageSize);
    }
  }
};
