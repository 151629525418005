import dmshttp from "@/utils/dmsrequest";

//分销商库存列表
export function getHisenseStock(data) {
    return dmshttp({
        url: "findInventoryHisenseList",
        data
    });
}
//我的库存列表导出
export function exportStock(data) {
    return dmshttp({
        url: 'exportInventoryHisenseList&type=1',
        data,
        responseType: 'blob'
    });
}

// 查询分销商
export function getConditionOptions(data) {
    return dmshttp({
        url: 'getConditionOptions',
        data
    })
}